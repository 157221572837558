import * as React from "react";
import { Link } from "gatsby";

import { buttonLink, disabled, transparent } from "./Button.module.scss";

interface ButtonLinkProps {
  to?: string;
  onClick?: () => void;
  label: string;
  disabled?: boolean;
  transparent?: boolean;

  className?: string;
}

export function ButtonLink(props: ButtonLinkProps) {
  const content = (
    <>
      <span>{props.label[0].toUpperCase()}</span>
      {props.label.slice(1)}
    </>
  );

  if (props.to) {
    return (
      <Link
        to={props.to}
        className={[
          buttonLink,
          props.disabled ? disabled : "",
          props.transparent ? transparent : "",
          props.className ? props.className : "",
        ].join(" ")}
      >
        {content}
      </Link>
    );
  }
  return (
    <button
      className={[
        buttonLink,
        props.disabled ? disabled : "",
        props.transparent ? transparent : "",
        props.className ? props.className : "",
      ].join(" ")}
      onClick={props.onClick}
    >
      {content}
    </button>
  );
}
