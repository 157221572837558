import React from "react";

import {
  formTextInput,
  formTextInputError,
  formInputDisabled,
} from "./Form.module.scss";

interface FormTextInputProps {
  field: string;
  label: string;
  type?: "password" | "email" | "textarea";
  error?: string;
  formatError?: (value: string) => string;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;

  onChange?: (value: string) => void;
}

export function FormTextInput(props: FormTextInputProps) {
  const { error } = props;
  const [displayError, setDisplayError] = React.useState(true);

  const formatError = (e: string) => {
    if (props.formatError) return props.formatError(e);
    switch (e) {
      case "This field may not be blank.":
        return e;
      default:
        return e;
    }
  };

  React.useEffect(() => {
    setDisplayError(true);
  }, [error]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<any>) => {
      setDisplayError(false);
      if (props.onChange) props.onChange(e.target.value);
    },
    [props.onChange]
  );

  const Component = React.useMemo(() => {
    if (props.type === "textarea")
      return (
        _props: React.DetailedHTMLProps<
          React.TextareaHTMLAttributes<HTMLTextAreaElement>,
          HTMLTextAreaElement
        >
      ) => <textarea {..._props} />;
    return (
      _props: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >
    ) => <input type={props.type || "text"} {..._props} />;
  }, [props.type]);

  return (
    <div
      className={[formTextInput, props.disabled ? formInputDisabled : ""].join(
        " "
      )}
    >
      <label>{props.label}</label>
      <Component
        name={props.field}
        defaultValue={props.defaultValue}
        onChange={handleChange}
        disabled={props.disabled}
        value={props.value}
      />
      {error && displayError ? (
        <label className={formTextInputError}>{formatError(error)}</label>
      ) : null}
    </div>
  );
}
