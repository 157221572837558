import React from "react";
import { navigate, PageProps } from "gatsby";
import { SEO } from "components/common/SEO";
import { Container, Layout } from "components/layout";
import {
  Form,
  FormTitle,
  FormTextInput,
  FormFooter,
  FormButton,
} from "components/common/Form";
import { ButtonLink } from "components/common/Button";
import { useAuth } from "auth";
import { ViewHeader } from "components/view/ViewHeader";

interface LoginPageProps extends PageProps { }

export default function LoginPage(props: LoginPageProps) {
  const { location: { state } } = props;
  const { login, token } = useAuth();

  React.useEffect(() => {
    login?.cleanErrors();
  }, []);

  const handleSubmit = async (data: FormData) => {
    login?.cleanErrors();
    await login?.process(data);
  };

  React.useEffect(() => {
    if (token) {
      if (state.redirectTo) navigate(state.redirectTo, {
        state: {
          locations: state.locations
        }
      });
      else navigate("/administration");
    }
  }, [token, state]);

  return (
    <Layout fullscreen dark>
      <SEO title="Login" />
      <ViewHeader dark />
      <Container className="login">
        <Form centered dark onSubmit={handleSubmit}>
          <FormTitle>Welcome back!</FormTitle>
          <FormTextInput
            field="username"
            label="Username"
            error={login?.errors?.["username"]?.[0]}
            formatError={(error) => {
              switch (error) {
                case "This field may not be blank.":
                  return "Enter your username.";
                default:
                  return error;
              }
            }}
          />
          <FormTextInput
            type="password"
            field="password"
            label="Password"
            error={
              login?.errors?.["password"]?.[0] || login?.errors?.["error"]?.[0]
            }
            formatError={(error) => {
              switch (error) {
                case "This field may not be blank.":
                  return "Enter your password.";
                case "Incorrect credentials":
                  return "Incorrect credentials.";
                default:
                  return error;
              }
            }}
          />
          <FormFooter>
            <FormButton>Sign in</FormButton>
            <ButtonLink to="/register" label="Create Account" />
          </FormFooter>
        </Form>
      </Container>
    </Layout>
  );
}
